import React, { Component } from "react";
import styles from "../css/Result.module.css";
import Toggle from "../../../globalcomponents/Toggle";
import { Link } from "react-router-dom";
import firstCapital from "../../../helper/firstCapital";
import WorkflowDialogBox from './../../Workflow/WorkflowDialogBox';
import CallDialogBox from './../../FPM/NewCallCaptureDialog';
import PharmActionDialog from './../../FPM/PharmActionDialog';
import CallDialogSelection from './../../FPM/CallDialogSelection';
import CallDialogConfirmation from './../../FPM/CallConfirmationDialog';
import YesNoDialog from './../../../globalcomponents/YesNoDialogBox';
import CallConfirmationDialog from "./../../FPM/CallConfirmationDialog";
import Axios from './../../../components/Axios'
class Result extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: props.active === props.number ? true : false,
            odd: parseInt(props.number) % 2 === 0,
            preview: props.preview,
            display: props.display,
            showWarning: false,
            cancelCRM: false,
            crmActiveState: false,
            aggActive: false,
            showED: false,
            CallDialog: '',
            showCallConfirmation: false,
            isFPM: ['FPM', 'Dir FPM', 'Sr Dir FPM'].includes(this.props.user.role)
        };
        console.log(this.props.user.role);
    }
    closeED() {
        this.setState({ showED: false });
    }
    closeCallDialog() {
        this.setState({ CallDialog: '' });
    }
    componentWillReceiveProps(newProp) {
        this.setState({
            active: newProp.active === newProp.number ? true : false,
            preview: newProp.preview,
        });
    }
    leftClick() {
        this.setState({ showWarning: false });

    }
    rightClick() {
        this.setState({ showWarning: false });

        this.props.addTo(this.props.number, 1, this.state.crmActiveState, !this.state.aggActive ? true : false);
        this.props.updateNotification(this.state.crmActiveState, !this.state.aggActive ? 'BOTH' : 'CRM');
    }
    openCTX(orgid) {
        this.setState({ showED: true });
    }
    submitCall(data) {

        Axios(`/api/fpm/save-call-capture`, data, () => {

            this.setState({ CallDialog: '', showCallConfirmation: true });

        })

    }
    submitPharmCall(data) {

        Axios(`/api/fpm/save-call-capture`, data, () => {

            this.setState({ CallDialog: '', showCallConfirmation: true });

        })

    }

    openCALL(mdm_id, is_hcp, orgname) {

        var username = this.props.user.userName;
        var terrid = this.props.user.terr;
        var terrname = this.props.user.terrname;

        this.setState({ CallDialog: (<CallDialogBox terrid={terrid} terrname={terrname} org_name={orgname} mdm_id={mdm_id} submit={this.submitCall.bind(this)} is_hcp={is_hcp} user={username} close={this.closeCallDialog.bind(this)} />) });

    }
    openPharmForm(mdm_id, is_hcp, orgname) {
        var username = this.props.user.userName;
        var terrid = this.props.user.terr;
        var terrname = this.props.user.terrname;
        var role = this.props.user.role;



        this.setState({ CallDialog: (<PharmActionDialog terrid={terrid} terrname={terrname} org_name={orgname} mdm_id={mdm_id} submit={this.submitPharmCall.bind(this)} is_hcp={is_hcp} user={username} close={this.closeCallDialog.bind(this)} />) });

    }
    openFPMMenu(mdm_id, is_hcp, orgname) {

        var username = this.props.user.userName;
        var terrid = this.props.user.terr;
        var terrname = this.props.user.terrname;

        this.setState({
            CallDialog: (<CallDialogSelection openPharmForm={this.openPharmForm.bind(this)} openCallForm={this.openCALL.bind(this)} terrid={terrid} terrname={terrname} org_name={orgname} mdm_id={mdm_id} submit={this.submitCall.bind(this)} is_hcp={is_hcp} user={username} close={this.closeCallDialog.bind(this)} />)
        });


    }
    closeCallConf() {

        this.setState({ CallDialog: '', showCallConfirmation: false });
    }
    render() {
        let hashes = this.props.hashes;
        let id = this.props.type === "/hcp" ? this.props.data.PROF_ID : this.props.data.ORG_ID;

        let aggspendActive = hashes.spend[id] || this.props.data.AGGSPEND_FLAG === "Y";
        let aggspendToggle = (
            <Toggle
                active={aggspendActive}
                click={() => {
                    this.props.addTo(this.props.number, 0, aggspendActive);
                    this.props.updateNotification(aggspendActive, "Spend");
                }}
                disabled={aggspendActive}
                errorCallback={() => {
                    this.props.updateError(aggspendActive, "Spend", id);
                }}
                error={this.props.errorByID === id && this.props.notificationType === "Spend"}
            />
        );
        //let ctxMenu = this.props.data.MDM_TERR_ID && this.props.data.MDM_TERR_ID.includes(this.props.user.terr) && this.props.type === "/hco" ?



        let callBtn = (<div><div className={styles.ActionButton} onClick={() => { this.openCTX(id); }}>E&D</div></div>);

        let ctxMenu = this.props.type === "/hco" && ['OHS', 'DTS', 'OBD', 'DBD', 'SA NAM', 'MARKET ACCESS', 'RAM', 'DIR', 'NAM', 'Dir FPM', 'Sr Dir FPM', 'FPM', 'ASD', 'ABD', 'MEDICAL AFFAIRS', 'OCNE'].includes(this.props.user.role) ? (<div ><div className={styles.ActionButton} onClick={() => { this.openCTX(id); }}>E&D</div></div>) : '';



        let isSAPEnabled = this.props.data.SOURCES == undefined ? false : this.props.data.SOURCES.includes("SAP");  //.includes("SAP");



        let EDDialog =
            (<WorkflowDialogBox tin={this.props.data.TIN} org_id={id} is_edit={false} close={this.closeED.bind(this)} isSAP={isSAPEnabled} user={this.props.user} />)

        //let CallDialog = (<CallDialogBox  />)
        // let CallConfDialog = (<CallConfirmationDialog close={ this.closeCallConf.bind(this) } />)

        let isAdmin = ["ADMIN"].includes(this.props.user.role);
        let isPRT = ['PRT'].includes(this.props.user.role)
        let isCommDerm = ['COMMERCIAL DERM'].includes(this.props.user.role)
        let regex = new RegExp(`^${this.props.user.terr}`, 'g')
        let matchesTerritory = false

        let overRide = this.props.data.MDM_OVERRIDE !== "X";
        if (overRide) {
            matchesTerritory = (["51", "53"].includes(this.props.user.team)) || (this.props.data.TERR_ID && this.props.data.TERR_ID.match(regex))
        } else {
            //matchesTerritory = ["51", "53"].includes(this.props.user.team) || (this.props.data.TERR_ID && (this.props.data.TERR_ID.match(regex) || this.props.data.MDM_TERR_ID.match(regex)))
            matchesTerritory = ["51", "53"].includes(this.props.user.team) ||
                (this.props.data.TERR_ID && (this.props.data.TERR_ID.match(regex) || (this.props.data.MDM_TERR_ID && this.props.data.MDM_TERR_ID.match(regex))))

        }

        let universeFlag = this.props.data.UNIVERSE_FLAG === "Y";
        let teamUniverseFlag = this.props.data[`UNIVERSE_FLAG_TM${this.props.user.team}`] === "Y";

        let crmActive = false;
        let isDisabled = !isAdmin && !matchesTerritory && !isPRT && !isCommDerm;
        let isGlobal = !isAdmin && !matchesTerritory && teamUniverseFlag && overRide;

        if (this.props.user.isDermAdmin == 'true' || this.props.user.role == 'COMMERCIAL DERM' || this.props.user.role == 'PRT') {
            crmActive = hashes.crm[id] !== undefined ? hashes.crm[id] : teamUniverseFlag;
            if (teamUniverseFlag == true) { isGlobal = false; }
        } else {
            crmActive = hashes.crm[id] !== undefined ? hashes.crm[id] : overRide && ((isAdmin && universeFlag) || teamUniverseFlag);
        }

        let hasAffiliations = this.props.data.AFFILIATION_COUNT > 0;
        //   let sameTeam = this.props.data[`TARGET_FLAG_TM${this.props.user.team}`] === "Y";

        let crmToggle = (
            <Toggle
                active={crmActive}
                click={() => {
                    if (crmActive === true && hasAffiliations) {
                        this.setState({
                            showWarning: true,
                            crmActiveState: crmActive,
                            aggActive: aggspendActive
                        });
                    }
                    else {
                        this.props.addTo(this.props.number, 1, crmActive, !aggspendActive ? true : false);
                        this.props.updateNotification(crmActive, !aggspendActive ? "BOTH" : "CRM");
                    }

                }}
                overRide={!overRide}
                disabled={isDisabled}
                global={isGlobal}
                errorCallback={() => {
                    this.props.updateError(crmActive, "CRM", id);
                }}
                error={this.props.errorByID === id && this.props.notificationType === "CRM"}
            />
        );

        let affWarning =
            (<YesNoDialog
                rightClick={this.rightClick.bind(this)}
                leftClick={this.leftClick.bind(this)}
                rightButton={'YES'}
                leftButton={'NO'}
                title={'Remove From CRM'}
                msgFirstLine={'The selected organization has one or more affiliations.'}
                msgSecondLine={'Select No to go back.  Select Yes to remove from CRM.'} />)


        let ctmsActive = hashes.ctms[id] !== undefined ? hashes.ctms[id] : this.props.data.CTMS_FLAG === "Y";
        let ctmsToggle = (
            <Toggle
                overRide={!overRide}
                active={ctmsActive}
                click={() => {
                    this.props.addTo(this.props.number, 2, ctmsActive);
                    this.props.updateNotification(ctmsActive, "CTMS");
                }}
            />
        );

        let name = null;
        let address = null;
        let content = [];
        if (this.props.type === "/hcp") {

            let address1 = "";
            let address2 = "";
            let city = "";
            let state = "";
            let zip = "";

            name = `${this.props.data.FIRST_NAME}${this.props.data.MIDDLE_NAME ? " " + this.props.data.MIDDLE_NAME : ""} ${this.props.data.LAST_NAME}`;
            if (this.props.user.role.includes('DEVELOPMENT OPERATIONS') && this.props.user.role.includes('CTMS')) {
                address1 = this.props.data.CTMS_ADDRESS1 !== null && this.props.data.CTMS_ADDRESS1 !== undefined ? `${firstCapital(this.props.data.CTMS_ADDRESS1)}, ` : "";
                address2 = this.props.data.CTMS_ADDRESS2 !== null && this.props.data.CTMS_ADDRESS2 !== undefined ? `${firstCapital(this.props.data.CTMS_ADDRESS2)}, ` : "";
                city = this.props.data.CTMS_CITY !== null && this.props.data.CTMS_CITY !== undefined ? `${firstCapital(this.props.data.CTMS_CITY)}, ` : "";
                state = this.props.data.CTMS_STATE !== null && this.props.data.CTMS_STATE !== undefined ? `${this.props.data.CTMS_STATE} ` : "";
                zip = this.props.data.CTMS_ZIP5 !== null && this.props.data.CTMS_ZIP5 !== undefined ? `${this.props.data.CTMS_ZIP5}` : "";
            }
            else {

                address1 = this.props.data.PRI_ADDRESS1 !== null && this.props.data.PRI_ADDRESS1 !== undefined ? `${firstCapital(this.props.data.PRI_ADDRESS1)}, ` : "";
                address2 = this.props.data.PRI_ADDRESS2 !== null && this.props.data.PRI_ADDRESS2 !== undefined ? `${firstCapital(this.props.data.PRI_ADDRESS2)}, ` : "";
                city = this.props.data.PRI_CITY !== null && this.props.data.PRI_CITY !== undefined ? `${firstCapital(this.props.data.PRI_CITY)}, ` : "";
                state = this.props.data.PRI_STATE !== null && this.props.data.PRI_STATE !== undefined ? `${this.props.data.PRI_STATE} ` : "";
                zip = this.props.data.PRI_ZIP5 !== null && this.props.data.PRI_ZIP5 !== undefined ? `${this.props.data.PRI_ZIP5}` : "";

            }


            address = address1 + address2 + city + state + zip;
            content.push("DEGREE");
            content.push("SPECIALTY");
        } else {
            name = this.props.data.ORG_NAME;
            let address1 = this.props.data.ADDRESS1 !== null && this.props.data.ADDRESS1 !== undefined ? `${firstCapital(this.props.data.ADDRESS1)}, ` : "";
            let address2 = this.props.data.ADDRESS2 !== null && this.props.data.ADDRESS2 !== undefined ? `${firstCapital(this.props.data.ADDRESS2)}, ` : "";
            let city = this.props.data.CITY !== null && this.props.data.CITY !== undefined ? `${firstCapital(this.props.data.CITY)}, ` : "";
            let state = this.props.data.STATE !== null && this.props.data.STATE !== undefined ? `${this.props.data.STATE} ` : "";
            let zip = this.props.data.ZIP5 !== null && this.props.data.ZIP5 !== undefined ? `${this.props.data.ZIP5}` : "";
            address = address1 + address2 + city + state + zip;
            content.push(["MSL"].includes(this.props.user.role) ? "CTMS_NAME" : "PREFERRED_NAME");
            content.push("ORG_TYPE");
        }

        return (
            <div>
                <div
                    className={this.state.odd ? (this.state.active ? [styles.Result, styles.Odd, styles.Active].join(" ") : [styles.Result, styles.Odd].join(" ")) : this.state.active ? [styles.Result, styles.Active].join(" ") : styles.Result}>
                    <div onClick={this.props.deactivate} className={styles.Status}>
                        {this.props.data.STATUS === null || this.props.data.ORG_STATUS === null ?
                            <i className="fas fa-check-circle" id={styles.StatusActive}></i> :
                            <i className="fas fa-times-circle" id={styles.StatusInactive}></i>}
                    </div>
                    <div onClick={this.props.deactivate} className={styles.Info}>
                        <Link
                            to={{
                                pathname: `${this.props.type}/details/${this.props.type === "/hcp" ? this.props.data.PROF_ID : this.props.data.ORG_ID}`,
                                state: {
                                    from: this.props.type,
                                },
                            }}
                        >
                            <div className={styles.Name}>{name}</div>
                        </Link>
                        <div className={styles.Address}>{address}</div>
                    </div>
                    <div
                        className={styles.Preview}
                        onClick={() => {
                            this.props.display();
                        }}
                    >
                        <i id={this.state.preview ? styles.Eye : styles.whiteEye} className="fas fa-eye"></i>
                    </div>
                    <div onClick={this.props.deactivate} className={styles.Degree}>
                        {this.props.data[content[0]]}
                    </div>
                    <div onClick={this.props.deactivate} className={styles.Specialty}>
                        {this.props.data[content[1]]}
                    </div>
                    {["COMPLIANCE", "DEVELOPMENT OPERATIONS-CTMS", "ADMIN"].includes(this.props.role) ? (
                        <div onClick={this.props.deactivate} className={styles.CTMS}>
                            {ctmsToggle}
                        </div>
                    ) : (
                        ""
                    )}
                    {(["OCNE", "DTS", "DBD", "NAM", "SA NAM", "ADMIN", "PRT"].includes(this.props.role) ||
                        ["11", "12", "21", "31", "51", "53"].includes(this.props.user.team)) && this.props.role != 'PHARMFORCE' ? (
                        <div onClick={this.props.deactivate} className={styles.CRM}>
                            {crmToggle}
                        </div>
                    ) : (
                        ""
                    )}
                    <div onClick={this.props.deactivate} className={styles.Spend}>
                        {aggspendToggle}
                    </div>
                    <div className={styles.Spend}>
                        { ctxMenu}{/*{this.props.type === "/hco"  ? callBtn : ''}*/}
                    </div>
                </div>
                {this.state.showWarning ? affWarning : ""}
                {this.state.showED ? EDDialog : ""}
                {/*{this.state.CallDialog}*/}
                {/*{this.state.showCallConfirmation ? CallConfDialog : ""}*/}

            </div>
        );
    }
}

export default Result;
