import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import ExerciseAdminPage from '../../../components/ExerciseAdminPage'
//import HcpUserTitles from './components/HCP/UserTitles'
import HcpResult from './components/HCP/Result'
import HcoUserTitles from './components/HCO/UserTitles'
import HcoResult from './components/HCO/Result'
import Approve from './components/Approve'
import Axios from '../../../components/Axios'
import styles from './css/Result.module.css';

function Admin(props) {

    const [data, setData] = useState([])
    const [loaded, setLoaded] = useState(false)
    const {idSlug} = useParams()

    let adminExercise = "";
    let isAdminUser = props.user.role === "ADMIN" || (props.user.role == 'DBD' && props.user.team == '31');

    if (isAdminUser) {
        adminExercise = props.exercise.filter(function (o) { return o.ID == idSlug; })[0];
    }

    useEffect(() => {
        if (isAdminUser) {
            Axios('/api/targeting/approval-check', { ID: adminExercise.ID }, (data) => {
                props.setApproved(data.length > 0)
            });
        }
    }, [])

    const getData = () => {

        let terr_id_temp = (props.user.role == 'DBD' && props.user.team == '31') && adminExercise != undefined ? props.user.terr : isAdminUser && adminExercise != undefined ? adminExercise.TEAM : props.user.terr;

        if ((props.user.role !== "ADMIN" && (props.user.terr === 'undefined' || props.user.terr === ''))) {
            if (adminExercise != undefined && adminExercise.TEAM != terr_id_temp.substr(0, 2)) {

                terr_id_temp = adminExercise.TEAM + terr_id_temp.substr(2, terr_id_temp.length - 2);

            }
        }
        let temp = {
            terr: terr_id_temp,
            id: isAdminUser && adminExercise != undefined ? adminExercise.TARGETING_EXERCISE_ID : props.exercise.TARGETING_EXERCISE_ID,
            type: isAdminUser && adminExercise != undefined ? adminExercise.CUSTOMER_TYPE : props.exercise.CUSTOMER_TYPE,
            team: isAdminUser && adminExercise != undefined ? adminExercise.TEAM : props.exercise.TEAM,
            user: props.user.userName
        }

        Axios('/api/targeting/get-current-totals', temp, (data) => {
            setData(data)
            setLoaded(true)
        })
    }
    const endpoints = {
        status: '/api/targeting/get-statuses',
        editStatus: '/api/targeting/edit-status'
    }

    let dynamicBox = ""
    let terr = isAdminUser && adminExercise != undefined ? adminExercise.TEAM.toString() : props.user.terr

    console.log(terr)

    if (terr.startsWith("11") || terr.startsWith("31") || terr.startsWith("32")) {
        dynamicBox = (
            [<div className={[styles.col, styles.short, styles.center].join(' ')}>C</div>
                
            /*<div className={[styles.col, styles.short, styles.center].join(' ')}>E</div>*/])
    }
    else if (terr.startsWith("21")) {
        dynamicBox = (
            [<div className={[styles.col, styles.short, styles.center].join(' ')}>C</div>])
    }

    let hcpUserTitles = () => {
        return (
            <div className={styles.titles}>
                <div className={[styles.col, styles.long].join(' ')}>TERRITORY</div>
                <div className={[styles.col, styles.short, styles.center].join(' ')}>Rep Name</div>
                <div className={[styles.col, styles.short, styles.center].join(' ')}>A</div>
                <div className={[styles.col, styles.short, styles.center].join(' ')}>B</div>

                {dynamicBox}
                
                {/*<div className={[styles.col, styles.short, styles.center].join(' ')}>NT</div>*/}
                <div className={[styles.col, styles.select, styles.center].join(' ')}>STATUS</div>
                <div className={[styles.col, styles.long, styles.center].join(' ')}>ACTIONS</div>
            </div>
        );
    }

    return (
        <ExerciseAdminPage
            {...props}
            headerTitle={'Targeting Exercises'}
            adminExercise={adminExercise}
            getData={getData}
            data={data}
            setData={setData}
            loaded={loaded}
            setLoaded={setLoaded}
            endpoints={endpoints}
            titles={hcpUserTitles}
            result={HcpResult}
            approve={Approve}
        />
    )
}

export default Admin
