import React, { useState, useEffect } from 'react'
import styles from './../css/StatusBar.module.css'
import StatusBox from './StatusBox.js'
import StatusLock from './StatusLock.js'
import Axios from '../../../../components/Axios'

function StatusBar(props) {
    const [statusTable, setStatusTable] = useState()

    useEffect(() => {
        Axios('/api/targeting/get-statuses', { team: props.user.role === "ADMIN" || (props.user.role == 'DBD' && props.user.team == '31') ? props.adminExercise.TEAM : props.exercise.TEAM }, (data) => {
            setStatusTable(data)
        })
    }, [])

    const statusIndex = props.status ? props.status.STATUS_ID : 0

    const currentA = props.status ? props.status.CURRENT_TOTAL_A : 0
    const currentB = props.status ? props.status.CURRENT_TOTAL_B : 0
    const currentC = props.status ? props.status.CURRENT_TOTAL_C : 0
    const currentE = props.status ? props.status.CURRENT_TOTAL_E : 0
    const currentNT = props.status ? props.status.CURRENT_TOTAL_NT : 0
    const currentNPP = props.status ? props.status.CURRENT_TOTAL_NPP : 0
    const finalA = props.status ? props.status.FINAL_TOTAL_A : 0
    const finalB = props.status ? props.status.FINAL_TOTAL_B : 0
    const finalC = props.status ? props.status.FINAL_TOTAL_C : 0
    const finalE = props.status ? props.status.FINAL_TOTAL_E : 0
    const finalNT = props.status ? props.status.FINAL_TOTAL_NT : 0
    const finalNPP = props.status ? props.status.FINAL_TOTAL_NPP : 0

    let dynamicBox = ""

    

    if (props.terr.startsWith("11") || props.terr.startsWith("12")) {
        dynamicBox = (<StatusBox target='E' color={"#000057"} current={currentE} final={finalE} team={ props.team} />)
    }
    else if (props.terr.startsWith("21")) {
        dynamicBox = ''; //(<StatusBox target='NPP' color={"#000057"} current={currentNPP} final={finalNPP} />)
    }

    if (props.terr.startsWith("11") || props.terr.startsWith("12") || props.terr.startsWith("21")) {

        //console.log('f');

        return (<div className={styles.statusBar}>
            <StatusBox target='A' color={"#58ac17"} current={currentA} final={finalA} team={props.team} />
            <StatusBox target='B' color={"#436B24"} current={currentB} final={finalB} team={props.team} />
            <StatusBox target='C' color={"#41658A"} current={currentC} final={finalC} team={props.team} />

          

            {/*<StatusBox target='NT' color={"#7F7F7F"} current={currentNT} final={finalNT} />*/}

            <div className={styles.box}>
                <div className={styles.statusTopBar}>STATUS</div>
                <div className={styles.status}>{statusTable && props.status ? statusTable[statusIndex - 1].STATUS : "Loading..."}</div>
            </div>
            <StatusLock {...props} adminExercise={props.adminExercise} statusIndex={statusIndex} />
        </div>)
    }
    else if (props.terr.startsWith("31") || props.terr.startsWith("32")) {

        //console.log('f');

        return (<div className={styles.statusBar31}>
            <div className={styles.box}>
                <div className={styles.statusTopBar}>STATUS</div>
                <div className={styles.status}>{statusTable && props.status ? statusTable[statusIndex - 1].STATUS : "Loading..."}</div>
            </div>
            <StatusLock {...props} adminExercise={props.adminExercise} statusIndex={statusIndex} />
        </div>)
    }
}

export default StatusBar
